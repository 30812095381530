<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="4">
        <v-autocomplete
          outlined
          dense
          v-model="record.FldAbs"
          item-text="DispName"
          item-value="id"
          label="Sub-Levels"
          :items="SubLevelCodes"
        ></v-autocomplete>
      </v-col>
      <v-col cols="4">
        <v-text-field
          outlined
          dense
          label="Code"
          v-model="record.AttrValue"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-btn color="accent" @click="sendData" :loading="loader">
          <v-icon left>mdi-content-save</v-icon>Save
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
export default {
  props: {
    initial: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    record: {},
    loader: false,
    SubLevelCodes: [],
  }),
  watch: {
    initial: {
      handler: "init",
      immediate: true,
    },
  },
  methods: {
    init(val) {
      this.record = { ...val };
    },
    getLocData() {
      const self = this;
      this.$store
        .dispatch("get", `/bin-locations/fields?FieldType=A`)
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.SubLevelCodes = res.ResponseData;
          }
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    sendData() {
      this.loader = true;
      this.$emit("data", this.record);
    },
  },
  created() {
    this.getLocData();
  },
};
</script>